<template>
  <v-container>
    <v-tabs v-model="tab" background-color="info" dark>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.content">
        <v-data-table
          v-if="tab == 0"
          :headers="dessertHeaders"
          :items="Users"
          item-key="_id"
          show-expand
          :single-expand="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.balance="{ item }">
            <span>{{ DisplayCurrency(item.wallet[0].balance) }}</span>
          </template>
          <template v-slot:item.userType="{ item }">
            <ActivateB2B v-if="item.userType != 'b2b'" :user="item" />
            <span v-else>{{ item.userType }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card-actions>
                <v-spacer />
                <div>
                  <UserWalletTransactions :userId="item._id" />
                </div>
                <div>
                  <MessageSystem :online="true" :userId="item._id" />
                </div>
                <div>
                  <UserPackages
                    :status="status"
                    :userId="item._id"
                    :tab="tab"
                  />
                </div>
                <div>
                  <FundOnlineWallet :user="item" />
                </div>
                <v-spacer />
              </v-card-actions>
              {{ item.null }}
            </td>
          </template>
        </v-data-table>
        <v-data-table
          v-if="tab == 2"
          :headers="dessertHeaders"
          :items="Users"
          item-key="_id"
          show-expand
          :single-expand="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.balance="{ item }">
            <span>{{ DisplayCurrency(item.balance) }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card-actions>
                <v-spacer />
                <div>
                  <UserWalletTransactions :userId="item._id" />
                </div>
                <div>
                  <MessageSystem :online="true" :userId="item._id" />
                </div>
                <div>
                  <UserPackages
                    :status="status"
                    :userId="item._id"
                    :tab="tab"
                  />
                </div>
                <div>
                  <FundOnlineWallet :user="item" />
                </div>
                <v-spacer />
              </v-card-actions>
              {{ item.null }}
            </td>
          </template>
        </v-data-table>
        <v-data-table
          v-if="tab == 1"
          :headers="dessertHeader"
          :items="Users"
          item-key="_id"
          show-expand
          :single-expand="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.balance="{ item }">
            <span>{{ DisplayCurrency(item.balance) }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card-actions>
                <v-spacer />
                <div>
                  <UserWalletTransactions v-if="tab == 0" :userId="item._id" />
                </div>
                <div>
                  <MessageSystem
                    :online="false"
                    :userId="item._id"
                    :phoneNumber="item.phoneNumber"
                  />
                </div>
                <div>
                  <UserPackages :userId="item._id" :tab="tab" />
                </div>
                <v-spacer />
              </v-card-actions>
              {{ item.null }}
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalItems"></v-pagination>
    </div>
  </v-container>
</template>
<script>
import moment from "moment";
import UserPackages from "../components/UserPackages.vue";
import UserWalletTransactions from "../components/UserWalletTransactions.vue";
import MessageSystem from "../components/MessageSystem.vue";
import FundOnlineWallet from "../components/FundOnlineWallet.vue";
import currencyFormatter from "currency-formatter";

export default {
  components: {
    UserPackages,
    UserWalletTransactions,
    MessageSystem,
    FundOnlineWallet,
  },
  data() {
    return {
      expanded: [],
      tab: null,
      status: "online",
      items: [
        { tab: "Online Customers", content: "OnlineCustomers" },
        { tab: "Offline Customers", content: "OfflineCustomers" },
      ],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "First Name",
          align: "start",
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Wallet Balance", value: "balance" },
        { text: "Phone Number", value: "phoneNumber" },
      ],
      dessertHeader: [
        {
          text: "First Name",
          align: "start",
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Phone Number", value: "phoneNumber" },
      ],
      page: 1,
      Currpage: 1,
    };
  },
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    Users() {
      return this.$store.getters.Users;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    getAllCustomers() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("getAllUsers", payload);
    },
  },
  created() {
    this.getAllCustomers();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        if (status == "online") {
          this.$store.dispatch("getAllUsers", payload);
        }
        if (status == "offline") {
          this.$store.dispatch("getAllOfflineUsers", payload);
        }
        return;
      }
    },
    tab: function (val) {
      if (val == 0) {
        this.status = "online";
        this.$store.commit("setCustomerStatus", "online");
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "online",
        };
        this.$store.dispatch("getAllUsers", payload);
      }
      if (val == 1) {
        this.status = "offline";
        this.$store.commit("setCustomerStatus", "offline");
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "offline",
        };
        this.$store.dispatch("getAllOfflineUsers", payload);
      }
    },
  },
};
</script>