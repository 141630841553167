<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="primary ma-2" dark v-bind="attrs" v-on="on">
          Message
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Message System</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-if="online"
                  shaped
                  filled
                  v-model="MessageType"
                  :items="['SMS', 'Push Notification']"
                  label="Type of message"
                  required
                ></v-select>
                <v-text-field
                  shaped
                  filled
                  v-if="online && MessageType != 'SMS'"
                  label="Title"
                  hint="# Parcel arrival"
                  v-model="title"
                  persistent-hint
                ></v-text-field>
                <v-textarea
                  filled
                  auto-grow
                  label="Enter Message"
                  rows="4"
                  row-height="30"
                  shaped
                  v-model="message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <div class="text-end">
            <v-btn
              @click="SendPushNotification"
              v-if="MessageType != 'SMS'"
              rounded
              color="pink"
              dark
            >
              Send App Notification
            </v-btn>
            <v-btn
              @click="SendSMSToOnlineCustomers"
              v-else
              rounded
              color="pink"
              dark
            >
              Send SMS
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["online", "userId", "phoneNumber"],
  data: () => ({
    dialog: false,
    deliverTO: "singleUser",
    message: "",
    title: "",
    MessageType: "SMS",
  }),
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    SendSingleSMS() {
      if (this.message == "" || this.phoneNumber == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        phoneNumber: this.phoneNumber,
      };
      this.$store.dispatch("SendSingleSMS", payload);
      this.message = "";
      this.title = "";
      this.deliverTO = "";
      this.dialog = false;
    },
    SendSMSToOnlineCustomers() {
      if (this.message == "" || this.deliverTO == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      if (this.phoneNumber) {
        this.SendSingleSMS();
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        title: this.title,
        deliverTO: this.deliverTO,
        userId: this.userId,
      };
      this.$store.dispatch("sendSMSToOnlineCustomers", payload);
      this.message = "";
      this.title = "";
      this.deliverTO = "";
      this.dialog = false;
    },
    SendPushNotification() {
      if (this.message == "" || this.deliverTO == "" || this.title == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        title: this.title,
        deliverTO: this.deliverTO,
        userId: this.userId,
      };
      this.$store.dispatch("SendPushNotification", payload);
      this.message = "";
      this.title = "";
      this.deliverTO = "";
      this.dialog = false;
    },
  },
};
</script>