var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-tabs',{attrs:{"background-color":"info","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.tab},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.content},[(_vm.tab == 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeaders,"items":_vm.Users,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.wallet[0].balance)))])]}},{key:"item.userType",fn:function(ref){
var item = ref.item;
return [(item.userType != 'b2b')?_c('ActivateB2B',{attrs:{"user":item}}):_c('span',[_vm._v(_vm._s(item.userType))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('UserWalletTransactions',{attrs:{"userId":item._id}})],1),_c('div',[_c('MessageSystem',{attrs:{"online":true,"userId":item._id}})],1),_c('div',[_c('UserPackages',{attrs:{"status":_vm.status,"userId":item._id,"tab":_vm.tab}})],1),_c('div',[_c('FundOnlineWallet',{attrs:{"user":item}})],1),_c('v-spacer')],1),_vm._v(" "+_vm._s(item.null)+" ")],1)]}}],null,true)}):_vm._e(),(_vm.tab == 2)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeaders,"items":_vm.Users,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.balance)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('UserWalletTransactions',{attrs:{"userId":item._id}})],1),_c('div',[_c('MessageSystem',{attrs:{"online":true,"userId":item._id}})],1),_c('div',[_c('UserPackages',{attrs:{"status":_vm.status,"userId":item._id,"tab":_vm.tab}})],1),_c('div',[_c('FundOnlineWallet',{attrs:{"user":item}})],1),_c('v-spacer')],1),_vm._v(" "+_vm._s(item.null)+" ")],1)]}}],null,true)}):_vm._e(),(_vm.tab == 1)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeader,"items":_vm.Users,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.balance)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card-actions',[_c('v-spacer'),_c('div',[(_vm.tab == 0)?_c('UserWalletTransactions',{attrs:{"userId":item._id}}):_vm._e()],1),_c('div',[_c('MessageSystem',{attrs:{"online":false,"userId":item._id,"phoneNumber":item.phoneNumber}})],1),_c('div',[_c('UserPackages',{attrs:{"userId":item._id,"tab":_vm.tab}})],1),_c('v-spacer')],1),_vm._v(" "+_vm._s(item.null)+" ")],1)]}}],null,true)}):_vm._e()],1)}),1),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.totalItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }