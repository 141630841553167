<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="primary ma-2" dark v-bind="attrs" v-on="on">
          View Parcel
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Parcel </v-card-title>
        <v-progress-linear
          v-if="TableLoading"
          indeterminate
          color="cyan"
        ></v-progress-linear>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Tracking NO.</th>
                <th class="text-left">Status</th>
                <th class="text-left">Shipping Fees</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in userPackage" :key="item.name">
                <td>{{ item.trackingNumber }}</td>
                <td>{{ item.Status }}</td>
                <td>{{ DisplayCurrency(item.shippingFee) }}</td>
                <td>{{ DisplayTimeFormat(item.createdAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="ActivitiesTotalItems"
          ></v-pagination>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import currencyFormatter from "currency-formatter";

export default {
  props: ["userId", "tab"],
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      desserts: [],
      page: 1,
      Currpage: 1,
    };
  },
  computed: {
    ActivitiesTotalItems() {
      let total = this.$store.getters.staffWalletTransactionTotalItems;
      return Math.ceil(total / 20);
    },
    userPackage() {
      return this.$store.getters.userPackage;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    customerStatus() {
      return this.$store.getters.customerStatus;
    },
  },
  methods: {
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
  },
  watch: {
    dialog: function (val) {
      if (val == true) {
        let payload = {
          token: this.getToken,
          page: 1,
          userId: this.userId,
          status: this.customerStatus,
        };
        this.$store.dispatch("getUserPackages", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
          userId: this.userId,
          status: this.customerStatus,
        };
        this.$store.dispatch("getUserPackages", payload);
        return;
      }
    },
  },
};
</script>