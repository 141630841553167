<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" rounded class="ma-2" dark v-bind="attrs" v-on="on">
          Fund Wallet
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Fund {{ user.firstName }} {{ user.lastName }} Wallet
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  shaped
                  filled
                  label="Amount*"
                  required
                  type="number"
                  v-model="amount"
                ></v-text-field>

                <v-textarea
                  filled
                  auto-grow
                  label="Description"
                  rows="4"
                  row-height="15"
                  shaped
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="pink" dark @click="FundWallet"> Fund Wallet </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["user"],
  data: () => ({
    dialog: false,
    amount: "",
    description: "",
  }),
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    FundWallet() {
      if (this.amount == "" || this.description == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        userId: this.user._id,
        token: this.getToken,
        amount: this.amount,
        description: this.description,
        walletId: this.user.wallet[0]._id,
      };
      this.$store.dispatch("fundOnlineUserWallet", payload);
      this.dialog = false;
      this.amount = "";
      this.description = "";
    },
  },
};
</script>